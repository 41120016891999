/**
 * @author hongweihu
 * @email hongweihu@fintopia.tech
 * @date 2021-12-30 14:28:59.678
 * @desc generated by yqg-cli@5.2.0
 */
import ContractUs from 'moneycan/app/lingyue-tech/component/contact-us';
import YqgFooter from 'moneycan/app/lingyue-tech/component/yqg-footer';
import YqgHeader from 'moneycan/app/lingyue-tech/component/yqg-header';
import ModalTypeMap from 'moneycan/common/constant/modal-type-map';

import ArticleList from '../constant/article-list';
import Vx from './img/vx.png';
import Alipay from './img/zhifubao.png';

export default {
    name: 'Detail',

    components: {YqgHeader, YqgFooter, ContractUs},

    head() {
        return {
            meta: [
                {name: 'robots', content: 'noindex,nofollow,noarchive'}
            ]
        };
    },

    title: '瓴岳科技',

    ModalTypeMap,

    data() {
        return {
            article: {},
            index: 0,
            nextArticle: null,
            preArticle: null,
            imgModal: {
                maskText: '',
                show: false,
                url: ''
            }
        };
    },

    methods: {
        init() {
            if (this.index > 7) {
                this.index = 7;
            }

            if (this.index < 0) {
                this.index = 0;
            }

            const {index} = this;
            this.article = ArticleList[index];
            const preIndex = index - 1;
            const nextIndex = index + 1;
            this.nextArticle = ArticleList[nextIndex];
            this.preArticle = ArticleList[preIndex];
        },

        pre() {
            this.index -= 1;
            this.init();
        },

        next() {
            this.index += 1;
            this.init();
        },

        openImgModal() {
            this.imgModal = {
                show: true,
                urlList: [Vx, Alipay]
            };
        },

        closeImgModal() {
            this.imgModal = {
                maskText: '',
                show: false,
                url: ''
            };
        },

        showContractUs() {
            this.$refs.contractUs.changeShow(true);
        }
    },

    created() {
        const {$route: {params: {index}}} = this;
        this.index = parseInt(index, 10) || 0;
        this.init(this.index);
    }
};
