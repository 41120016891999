import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=df5dd1a6&"
import script from "./detail.js?vue&type=script&lang=js&"
export * from "./detail.js?vue&type=script&lang=js&"
import style0 from "./detail.scss?vue&type=style&index=0&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci@2/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('df5dd1a6')) {
      api.createRecord('df5dd1a6', component.options)
    } else {
      api.reload('df5dd1a6', component.options)
    }
    module.hot.accept("./detail.vue?vue&type=template&id=df5dd1a6&", function () {
      api.rerender('df5dd1a6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/lingyue-tech/payment-information/detail/detail.vue"
export default component.exports