var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c("yqg-header", { on: { "show-contract-us": _vm.showContractUs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "article" }, [
          _c("h5", { staticClass: "article-title" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.article.title) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "money-tip" }, [
            _vm._v(
              "\n                收费标准：付费2元即可阅读全文\n            "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "article-content" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.article.content) +
                "\n            "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pay" }, [
            _c(
              "button",
              { staticClass: "pay-btn", on: { click: _vm.openImgModal } },
              [_vm._v("\n                    点击付费\n                ")]
            ),
          ]),
          _vm._v(" "),
          _vm.preArticle
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.pre },
                  },
                  [
                    _vm._v(
                      "\n                    上一篇：" +
                        _vm._s(_vm.preArticle.title) +
                        "\n                "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.nextArticle
            ? _c("div", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.next },
                  },
                  [
                    _vm._v(
                      "\n                    下一篇：" +
                        _vm._s(_vm.nextArticle.title) +
                        "\n                "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("yqg-footer", { on: { "show-contract-us": _vm.showContractUs } }),
      _vm._v(" "),
      _c("yqg-image-modal", {
        attrs: {
          "url-list": _vm.imgModal.urlList,
          open: _vm.imgModal.show,
          type: _vm.$options.ModalTypeMap.center,
        },
        on: {
          click: function ($event) {
            return _vm.closeImgModal()
          },
        },
      }),
      _vm._v(" "),
      _c("contract-us", { ref: "contractUs" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }